* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: Mulish;
  src: url("./assets/Fonts/Mulish/Mulish-VariableFont_wght.ttf");
}
.ff-mulish {
  font-family: Mulish;
}
.w-100 {
  width: 100%;
}
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}
::-webkit-scrollbar {
  height: 8px; /* Height of the horizontal scrollbar */
}
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the scrollbar track */
}
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounded corners for the scrollbar handle */
}
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}
.hide-outline:focus {
  outline: none;
}
.cutoff-text {
  --max-lines: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}
.cutoff-text-one-line {
  --max-lines: 1;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}
.hover-effect:hover {
  background-color: aqua;
}
.cursor-pointer {
  cursor: pointer;
}
.text-underline:hover {
  text-decoration: underline;
}
th {
  text-align: center;
}
.otp-box {
  width: 5%;
}
.signup-width {
  width: 50%;
}

.customModal {
  max-width: 900px; /* Change to desired width */
}

.no_border {
  border: none;
}
